/* .cross {
  position: absolute;
} */

.cross::after {
  pointer-events: none;
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.cross1::after {
  background: linear-gradient(
      to top left,
      transparent 45%,
      rgba(255, 0, 0, 0.35) 46%,
      rgba(255, 0, 0, 0.35) 54%,
      transparent 55%
    ),
    linear-gradient(
      to top right,
      transparent 45%,
      rgba(255, 0, 0, 0.35) 46%,
      rgba(255, 0, 0, 0.35) 54%,
      transparent 55%
    );
}
div {
  width: '30%';
  /* margin: auto; */
}
