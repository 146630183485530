/* @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap'); */

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
@font-face {
  font-family: 'Roboto Condensed Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Roboto Condensed Regular'),
    url('./fonts/RobotoCondensed-Regular.woff') format('woff');
}

body {
  background: #ebf2f5;
  font-family: 'Roboto Condensed Regular', sans-serif;
}

.fundo {
  padding: 3rem 3rem 3rem 3rem;
  
}
